




































import { Component, mixins, Prop } from 'nuxt-property-decorator'
import { defineComponent } from '@nuxtjs/composition-api'
import { SectionCore } from '../mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic'
import Tabs from '@/components/Tabs/index.vue'
import BlockText from '@/components/BlockText.vue'
import BlockButtons from '@/components/BlockButtons.vue'

@Component
class SectionImageText extends mixins(SectionCore) {
  @Prop() readonly list: ReadonlyArray<any> = []
  static options: any
}

export default defineComponent({
  name: 'SectionVacancy',
  components: { BlockButtons, BlockText, SectionBasic, Tabs },
  props: SectionImageText.options.props,
  setup() {
    const sizes = {
      sm: [343, 258],
      md: [980, 326],
      lg: [980, 326],
    }
    return {
      sizes,
    }
  },
})
